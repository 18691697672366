<template>
  <div>
  <v-card>
    <v-divider class="mt-4"></v-divider>

    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
        :aria-label="t('Buscar por nombre de region')"
        :placeholder="t('Buscar por nombre de region')"
        outlined
        hide-details
        dense
        class="me-3 mb-4"
        v-model="searchQuery"
        @keyup="handleSearch"
      >
      <!-- v-model="searchQuery" -->
        <!-- @keyup="handleSearch" -->
      </v-text-field>
      <v-spacer></v-spacer>
				<v-select v-if="getUserRole < 2"
					style="width: 0px"
					class="mb-4"
					:items="[{name: '-', id: -1},...countryList]"
					hide-details="true"
					:label="t('País')"
					item-text="name"
					item-value="name"
					outlined
					dense
					v-model="stateFilterCountry"
					@change="setFilterCountry"
					>

          <template v-slot:selection="data">
            {{ countryNameParser[data.item.name] }}
          </template>

          <template v-slot:item="data">
            {{ countryNameParser[data.item.name] }}
          </template>
        </v-select>

      <v-spacer></v-spacer>

      <div class="d-flex align-center flex-wrap">
      <v-btn
        v-if="getUserRole === 0 || getUserRole === 1"
        color="primary"
        class="mb-4 me-3"
        @click="$refs.region_form_modal.openModal()"
        >
      <v-icon>{{ icons.mdiPlus }}</v-icon>
      <span>{{t('Crear Region')}}</span>
      </v-btn>

    </div>
    </v-card-text>
      <!-- table -->
      <v-data-table
      :no-data-text="getError ? getError : $t('No hay datos disponibles.')"
      :headers="tableColumns"
      :items="regionListTable"
      :page="current_page"
      :server-items-length="totalRegionListTable"
      :loading="loading"
      @update:page="changingRegionsPage($event)"
      @update:items-per-page="changingRegionsPerPage($event)"
      @update:sort-desc="sortingPage($event)"
      :footer-props="{
          'items-per-page-options': [
            10,
            25,
            50,
            100
          ],
          'items-per-page-text': `${$t('Items por página')}`,
          'page-text': `{0}-{1} ${$t('de')} {2}`,
        }"
      >

      <!-- name -->
      <template #[`item.name`]="{item}">

        <div class="d-flex flex-column ms-3">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.name}}</span>
        </div>

      </template>

      <!-- country -->
      <template #[`item.country`]="{item}">
        {{ `${parseCountry(item.country)}` }}
      </template>

      <!-- action -->
      <template #[`item.actions`]="{ item }">

        <v-btn
          class="me-2 icon-with-label"
          color="alt-primary"
          text
          @click="$refs.region_form_modal.openModal(item.id)"
        >
          <div class="d-flex align-center">
            <span class="label">
              {{$t('Editar')}}
            </span>
            <v-icon size="1.5rem">
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </div>
        </v-btn>

        <v-btn
          class="me-2 icon-with-label"
          color="error"
          text
          @click="$refs.delete_region_modal.openModal(item)"
        >
          <div class="d-flex align-center">
            <span class="label">
              {{$t('Eliminar')}}
            </span>
            <v-icon size="1.5rem">
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </div>
        </v-btn>

      </template>

      <template #[`item.report`]="{item}">
        <v-btn 
          class="me-2 icon-with-label"
          color="alt-primary"
          text
          @click="$refs.reports_modal.openModal(item, false)"
        >
          <div class="d-flex align-center">
            <span class="label">
              {{$t('Ver')}}
            </span>
            <v-icon size="1.5rem">
              {{ icons.mdiEyeOutline }}
            </v-icon>
          </div>
        </v-btn>
      </template>

      </v-data-table>

    <region-form-modal
      ref="region_form_modal"
      @reload-region-list="getRegionList()"
    />

    <delete-region-modal
      ref="delete_region_modal"
      @reload-region-list="getRegionList()"
    />

    <reports-modal ref="reports_modal" />

  </v-card>
  </div>
</template>

<script>
// const debounce = require('debounce');

// import data from './datatable'
import { ref } from '@vue/composition-api';
import RegionFormModal from '@/components/Regions/RegionFormModal';
import DeleteRegionModal from '@/components/Regions/DeleteRegionModal';
import ReportsModal from '../Countries/ReportsModal.vue';
import { useUtils } from '@core/libs/i18n'
import debounce from '../../plugins/debounce.js'

import {
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPencilOutline,
  mdiEyeOutline
} from '@mdi/js'


export default {
  components: {
    RegionFormModal,
    DeleteRegionModal,
    ReportsModal
  },
data: ()  => {
  return {
    countryList: [],
    current_page: 1,
    page_size: 10,
    sortDesc: false,
    loading: false,
    stateFilterCountry: -1,
    getError: null,
  }
},
methods: {
  async getRegionList() {
    this.loading = true
    try {
      const query = {
        page_size: this.page_size,
        page: this.current_page,
        search: this.searchQuery,
        country: this.stateFilterCountry,
        order: this.sortDesc
      }
      const response = await this.$api.getRegionList(query)
      this.regionListTable = response.results
      this.totalRegionListTable = response.count
    } catch (error) {
      if(error?.response?.status === 403) {
				this.getError = error?.response?.data?.detail
			}
    } finally {
      this.loading = false
    }
  },
  changingRegionsPage(e) {
    this.current_page = e
    this.getRegionList()
  },
  changingRegionsPerPage(e) {
    this.page_size = e
    this.getRegionList()
  },
  sortingPage(e) {
    this.sortDesc = e
    this.getRegionList()
  },
  setFilterCountry() {
			  this.current_page = 1
				this.getRegionList()
		},
  handleSearch: debounce(function() {
    this.current_page = 1;
    this.getRegionList();
  }, 400),

  async getCountryList() {
    try {
      const response = await this.$api.getCountryList({ paginated: false })
      this.countryList = response
    } catch(e) {
      console.log(e)
    }
  },
  parseCountry(id) {
    let countryName = ''
    this.countryList.forEach(el => {
      if(el.id === id) {
        countryName = this.countryNameParser[el.name]
      }
    })
    return countryName
  }
},
mounted() {
  this.getRegionList()
  if(this.getUserRole < 2)this.getCountryList()
},
computed: {
  tableColumns() {
    return [
      { text: this.$t('Nombre'), value: 'name', align: 'center'},
      { text: this.$t('País'), value: 'country', align: 'center', sortable: false },
      { text: this.$t('Acciones'), value: 'actions', align: 'center', sortable: false },
      { text: this.$t('Reportes'), value: 'report', align: 'left'},
    ]
  },
  countryNameParser() {
    const parser = {
      AR: 'Argentina',
      BO: 'Bolivia',
      BR: 'Brazil',
      CL: 'Chile',
      CO: 'Colombia',
      CR: 'Costa Rica',
      CU: 'Cuba',
      DO: this.$t('República Dominicana'),
      EC: 'Ecuador',
      GD: this.$t('Granada'),
      GF: this.$t('Guayana Francesa'),
      GT: 'Guatemala',
      GY: 'Guyana',
      HN: 'Honduras',
      HT: 'Haiti',
      JM: 'Jamaica',
      MX: 'Mexico',
      NI: 'Nicaragua',
      PA: 'Panama',
      PE: 'Perú',
      PR: 'Puerto Rico',
      PY: 'Paraguay',
      SR: 'Surinam',
      SV: 'El Salvador',
      UY: 'Uruguay',
      VE: 'Venezuela',
      IN: 'India'
    }
    return parser
  },
  getUserRole() { return this.$store.getters['session/getUserRole']() },
},
setup() {
  const totalRegionListTable = ref(0);
  const regionListTable = ref([]);
  const searchQuery = ref('');
  const { t } = useUtils()

  return {
    totalRegionListTable,
    regionListTable,
    searchQuery,
    t,
    icons: {
      mdiSquareEditOutline,
      mdiDotsVertical,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiEyeOutline
    },
  }
},
}
</script>

<style scoped>
.no-uppercase {
  text-transform: unset !important;
}
</style>
