<template>
  <v-dialog v-model="showingRegionModal" width="700" persistent minHeight="1200">
    <v-form ref="regionForm" autocomplete="new-password" @submit.prevent="createRegion">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ editFormId? $t('Editar') +' '+ $t('Region') : $t('Crear') +' '+ $t('Region') }}
        </v-card-title>
        <v-card-text style="max-height: 500px">
          <v-row class="mt-2">
            <v-col cols="6" class="mt-2">
              <v-text-field
                ref="name"
                v-model="regionName"
                outlined
                dense
                autocomplete="off"
                hide-details="auto"
                :rules="[() => !!regionName || $t('Campo requerido')]"
                :label="$t('Nombre de la Region')"
                />
            </v-col>
            <v-col cols="6">
              <v-select
                :items="countryList"
                hide-details="true"
                v-model="country"
                :label="$t('País')"
                :rules="[() => !!country || $t('Campo requerido')]"
                item-text="name"
                item-value="id"
              >

              <template v-slot:selection="data">
                {{ countryParser[data.item.name] }}
              </template>

              <template v-slot:item="data">
                {{ countryParser[data.item.name] }}
              </template>
            </v-select>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="tertiary"
            outlined
            @click="close()"
          >
            {{$t('Cancelar')}}
          </v-btn>
          <v-btn color="primary" type="submit">
            {{ editFormId ? $t('Editar') +' '+ $t('Region') : $t('Crear') +' '+ $t('Region') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>

export default {
  data: () => {
    return {
      editFormId: null,
      regionName: '',
      country: null,
      countryList: [],
      showingRegionModal: false
    }
  },
  methods: {
    openModal(id_to_edit) {
      this.getCountryList()
      id_to_edit
          ? this.setItemToEdit(id_to_edit)
          : this.showingRegionModal = true;
    },
    async setItemToEdit(id_to_edit) {
      const item = await this.$api.getRegion(id_to_edit);
      if (item) {
        this.editFormId = id_to_edit;
        this.regionName = item.name ?? null;
        this.country = item.country ?? null;
        this.showingRegionModal = true;
      }
    },
    async createRegion() {
        if (this.$refs.regionForm.validate()) {
        const form = this.parseForm();
        try {
          const response = this.editFormId
              ? await this.$api.updateRegion(this.editFormId, form)
              : await this.$api.createRegion(form);
          this.$emit("reload-region-list");
          this.close()
        }
        catch (err) {
          console.log(err);
        }
      }
    },
    close() {
      this.$refs.regionForm.reset()
      this.editFormId = null;
      this.showingRegionModal = false;
    },
    parseForm() {
      const form = {
        name: this.regionName,
        country: this.country
      }
      const formParsed = JSON.parse(JSON.stringify(form));
      return formParsed;
    },
    async getCountryList() {
      try {
        const response = await this.$api.getCountryList({ paginated: false })
        this.countryList = response
      } catch(e) {
        console.log(e)
      }
    }
  },
  computed: {
    countryParser() {
    const parser = {
      AR: 'Argentina',
      BO: 'Bolivia',
      BR: 'Brazil',
      CL: 'Chile',
      CO: 'Colombia',
      CR: 'Costa Rica',
      CU: 'Cuba',
      DO: this.$t('República Dominicana'),
      EC: 'Ecuador',
      GD: this.$t('Granada'),
      GF: this.$t('Guayana Francesa'),
      GT: 'Guatemala',
      GY: 'Guyana',
      HN: 'Honduras',
      HT: 'Haiti',
      JM: 'Jamaica',
      MX: 'Mexico',
      NI: 'Nicaragua',
      PA: 'Panama',
      PE: 'Perú',
      PR: 'Puerto Rico',
      PY: 'Paraguay',
      SR: 'Surinam',
      SV: 'El Salvador',
      UY: 'Uruguay',
      VE: 'Venezuela',
      IN: 'India'
    }
    return parser
  }
  }
}
</script>
