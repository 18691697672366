<template>
    <div>
      <v-card class="mb-6">
        <v-card-title>{{t('Regiones')}}</v-card-title>
        <region-list />
      </v-card>
  
    </div>
  </template>
  
  <script>
  import RegionList from '@/components/Regions/RegionList'
  import { useUtils } from '@core/libs/i18n'


  export default {
    components: {
      RegionList
  },
    setup() {
      const { t } = useUtils()
      return {
        t,
        icons: {
        },
      }
    },
  }
</script>