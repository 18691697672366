import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VTextField,{staticClass:"me-3 mb-4",attrs:{"aria-label":_vm.t('Buscar por nombre de region'),"placeholder":_vm.t('Buscar por nombre de region'),"outlined":"","hide-details":"","dense":""},on:{"keyup":_vm.handleSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VSpacer),(_vm.getUserRole < 2)?_c(VSelect,{staticClass:"mb-4",staticStyle:{"width":"0px"},attrs:{"items":[{name: '-', id: -1} ].concat( _vm.countryList),"hide-details":"true","label":_vm.t('País'),"item-text":"name","item-value":"name","outlined":"","dense":""},on:{"change":_vm.setFilterCountry},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.countryNameParser[data.item.name])+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.countryNameParser[data.item.name])+" ")]}}],null,false,1799467004),model:{value:(_vm.stateFilterCountry),callback:function ($$v) {_vm.stateFilterCountry=$$v},expression:"stateFilterCountry"}}):_vm._e(),_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[(_vm.getUserRole === 0 || _vm.getUserRole === 1)?_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.region_form_modal.openModal()}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v(_vm._s(_vm.t('Crear Region')))])],1):_vm._e()],1)],1),_c(VDataTable,{attrs:{"no-data-text":_vm.getError ? _vm.getError : _vm.$t('No hay datos disponibles.'),"headers":_vm.tableColumns,"items":_vm.regionListTable,"page":_vm.current_page,"server-items-length":_vm.totalRegionListTable,"loading":_vm.loading,"footer-props":{
          'items-per-page-options': [
            10,
            25,
            50,
            100
          ],
          'items-per-page-text': ("" + (_vm.$t('Items por página'))),
          'page-text': ("{0}-{1} " + (_vm.$t('de')) + " {2}"),
        }},on:{"update:page":function($event){return _vm.changingRegionsPage($event)},"update:items-per-page":function($event){return _vm.changingRegionsPerPage($event)},"update:sort-desc":function($event){return _vm.sortingPage($event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.name))])])]}},{key:"item.country",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(("" + (_vm.parseCountry(item.country))))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c(VBtn,{staticClass:"me-2 icon-with-label",attrs:{"color":"alt-primary","text":""},on:{"click":function($event){return _vm.$refs.region_form_modal.openModal(item.id)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('Editar'))+" ")]),_c(VIcon,{attrs:{"size":"1.5rem"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)]),_c(VBtn,{staticClass:"me-2 icon-with-label",attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.$refs.delete_region_modal.openModal(item)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('Eliminar'))+" ")]),_c(VIcon,{attrs:{"size":"1.5rem"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)])]}},{key:"item.report",fn:function(ref){
        var item = ref.item;
return [_c(VBtn,{staticClass:"me-2 icon-with-label",attrs:{"color":"alt-primary","text":""},on:{"click":function($event){return _vm.$refs.reports_modal.openModal(item, false)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('Ver'))+" ")]),_c(VIcon,{attrs:{"size":"1.5rem"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)])]}}],null,true)}),_c('region-form-modal',{ref:"region_form_modal",on:{"reload-region-list":function($event){return _vm.getRegionList()}}}),_c('delete-region-modal',{ref:"delete_region_modal",on:{"reload-region-list":function($event){return _vm.getRegionList()}}}),_c('reports-modal',{ref:"reports_modal"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }