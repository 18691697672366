import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"auto"},model:{value:(_vm.showingDeleteRegionModal),callback:function ($$v) {_vm.showingDeleteRegionModal=$$v},expression:"showingDeleteRegionModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.$t('¿Estás seguro de eliminar la region'))+" '"+_vm._s(_vm.region.name)+"'? ")]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"tertiary","outlined":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancelar'))+" ")]),_c(VBtn,{attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.deleteRegion()}}},[_vm._v(" "+_vm._s(_vm.$t('Eliminar'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }